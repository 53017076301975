import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './Plugin/fontAwesome';
import ThemeContextWrapper from './Context/ThemeContextWrapper';
// import { BrowserRouter } from "react-router-dom";
// import UserContexWrapper from './Context/UserContexWrapper';
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';
import history from './history';

import {Helmet} from "react-helmet";

import {persistor,store} from './Redux/store';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';

ReactDOM.render(
  <HistoryRouter history={history} basename='/'>
      <Helmet>
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://cause.id" />
        <meta property="og:image" content="https://cause.id/img/logo_square.png" />
        <meta name="theme-color" content="#B22A2B" />
        <meta name="twitter:card" content="summary_large_image"/>
      </Helmet>
      <Provider store={store}>
        <PersistGate persistor={persistor} loading={null}>
          <ThemeContextWrapper>
            {/* <UserContexWrapper> */}
              <App />
            {/* </UserContexWrapper> */}
          </ThemeContextWrapper>
        </PersistGate>
      </Provider>
  </HistoryRouter>
,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

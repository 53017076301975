import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from "react-router-dom";
import { useIdleTimer } from 'react-idle-timer';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { setData } from '../Redux/Slice/user';
import { useSelector, useDispatch } from 'react-redux';

const IdleTimer = (props) => {
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();
    const user = useSelector((state) => state.user.value);
    const navigate = useNavigate();

    // Toast notification function
    const notify = (message) => {
        toast(message, {
            position: toast.POSITION.TOP_CENTER,
            className: 'bg-red-500 font-semibold text-center text-sm py-0 text-white',
        });
    };

    // Function to handle when the user goes idle
    const handleOnIdle = () => {
        const isMobile = searchParams.get('mobile') == 'true';

        if (!isMobile) {
            if (user?.token) {
                notify("Session Expired");
                dispatch(setData({}));
                localStorage.removeItem("__xtropt")
                localStorage.removeItem("_a7as__tt56uid")
                navigate("/", { replace: true });
                window.location.reload();
            }
        }
    };

    // Function to handle user activity and reset the idle timer
    const handleOnAction = () => {
        // const newExpirationTime = Date.now() + 1000 * 3; // 30 sec
        const newExpirationTime = Date.now() + 1000 * 60 * 30; // 30 minutes
        localStorage.setItem("_expiredTime", newExpirationTime);
    };

    const { getRemainingTime, getLastActiveTime } = useIdleTimer({
        // timeout: 1000 * 3, // 30 sec
        timeout: 1000 * 60 * 30, // 30 minutes
        onIdle: handleOnIdle,
        onAction: handleOnAction,
        debounce: 500,
        crossTab: {
            emitOnAllTabs: true,
        },
    });

    // Check for session expiration when the component mounts (or when the tab is reopened)
    useEffect(() => {
        const expiredTime = localStorage.getItem("_expiredTime");
        if (expiredTime && Date.now() > expiredTime) {
            handleOnIdle(); // Trigger session expiration handling
        }
    }, []); // Run on component mount

    return (
        <div>
            {props.children}
        </div>
    );
};

export default IdleTimer;